/* eslint-disable @nx/enforce-module-boundaries */
import { forwardRef, useContext, useEffect, useMemo } from 'react';
import { PostBoxContext } from '../context';
import {
  Avatar,
  AvatarGroup,
  Box,
  Circle,
  Flex,
  HStack,
  Text,
} from '@mybridge/ui';
import { SuggestedPostIcon } from '@mybridge/icons';
import {
  HandShakeIcon,
  CommentsIcon,
  PinnedIcon,
  SuggestedIcon,
  RepostedIcon,
  QuotedIcon,
} from '@mybridge/icons/DashboardNewICons';

import { ShareIcon } from '@mybridge/icons/Share';
import { getUserFullName, getUserProfilePic } from 'v4/lib/commons';
import Link from 'next/link';
import { MyNetworkContext } from 'v4/components/network/context';
import { InsightPopover } from '../insight-popover';
import _ from 'lodash';
export const PostActivityHeader = forwardRef(
  ({ maxUserCount = 3, maxCount = 15, post, ...props }, ref) => {
    const { post: ps } = useContext(PostBoxContext);
    const { myFirstDegreeConnections, refetchMyFirstDegreeConnections } =
      useContext(MyNetworkContext);
    const { pinned } = props;
    const {
      comment_count,
      repost,
      like_count,
      dislike_count,
      liked_by,
      dislike_by,
      reposts_by,
      comment_by,
    } = ps ?? {};

    const likedBy = liked_by
      ?.filter?.((_, ind) => ind < maxCount)
      ?.map?.((lb, i) => <Box key={i}>{getUserFullName(lb)}</Box>);
    if (liked_by?.length > maxCount) {
      likedBy.push(<Box>and {liked_by?.length - maxCount} more</Box>);
    }
    const dislikedBy = dislike_by
      ?.filter?.((_, ind) => ind < maxCount)
      ?.map?.((lb, i) => <Box key={i}>{getUserFullName(lb)}</Box>);
    if (dislike_by?.length > maxCount) {
      dislikedBy.push(<Box>and {dislike_by?.length - maxCount} more</Box>);
    }
    const repostBy = _.uniqBy(reposts_by ?? [], (e) => e.id)
      ?.filter?.((_, ind) => ind < maxCount)
      .map?.((lb, i) => <Box key={i}>{getUserFullName(lb)}</Box>);
    if (reposts_by?.length > maxCount) {
      repostBy.push(<Box>and {reposts_by?.length - maxCount} more</Box>);
    }

    const commentBy = comment_by
      ?.filter?.((_, ind) => ind < maxCount)
      ?.map?.((lb, i) => <Box key={i}>{getUserFullName(lb)}</Box>);
    if (comment_by?.length > maxCount) {
      commentBy.push(<Box>and {comment_by?.length - maxCount} more</Box>);
    }

    const sharedBy = reposts_by
      ?.filter?.((_, ind) => ind < maxCount)
      ?.map?.((lb, i) => <Box key={i}>{getUserFullName(lb)}</Box>);
    if (reposts_by?.length > maxCount) {
      sharedBy.push(<Box>and {reposts_by?.length - maxCount} more</Box>);
    }

    const map = useMemo(() => {
      const post_ = post ?? ps ?? {};
      return [
        {
          append: ` Quoted post`,
          icon: <QuotedIcon color="#5B5B5B" />,
          count:
            post_.repost !== null && post_.content && post_.content !== ''
              ? Infinity
              : -Infinity,
          users: post_?.author,
          extra: Math.max((post_?.comment_by?.length ?? 0) - maxUserCount, 0),
          content: commentBy,
        },
        {
          // append: `${post_.comment_count >10 ? "+" + post_.comment_count/10 : "+" + post_.comment_count } Commented`,
          // append: `${post_.comment_count > 3 ? "+" + post_.comment_count - 3 : '' } Commented`,
          append: ` Commented`,
          icon: <CommentsIcon color="#5B5B5B" />,
          count: post_?.comment_count,
          users: post_?.comment_by,
          extra: Math.max((post_?.comment_by?.length ?? 0) - maxUserCount, 0),
          content: commentBy,
        },
        {
          // append: `${post_.like_count >10 ? "+" + post_.like_count/10 : "+" + post_.like_count } Agreed`,
          append: ` Agreed`,
          icon: <HandShakeIcon color="#037F29" />,
          count: post_?.like_count,
          users: post_?.liked_by,
          extra: Math.max((post_?.liked_by?.length ?? 0) - maxUserCount, 0),
          content: likedBy,
        },
        {
          // append: `${post_.dislike_count >10 ? "+" + post_.dislike_count/10 : "+" + post_.dislike_count } Disagreed`,
          append: ` Disagreed`,
          icon: <HandShakeIcon color="#E41C19" />,
          count: post_?.dislike_count,
          users: post_?.dislike_by,
          extra: Math.max((post_?.dislike_by?.length ?? 0) - maxUserCount, 0),
          content: dislikedBy,
        },
        {
          // append: `${post_.share_count >10 ? "+" + post_.share_count/10 : "+" + post_.share_count } Reposted`,
          append: ` Reposted`,
          icon: <RepostedIcon color="#5B5B5B" />,
          count: post_?.repost?.share_count,
          users: post_?.reposts_by,
          extra: Math.max((post_?.reposts_by?.length ?? 0) - maxUserCount, 0),
          content: sharedBy,
        },
      ];
    }, [post, ps]);
    const toShow = useMemo(
      () => map?.sort?.((a, b) => b.count - a.count)?.[0],
      [map]
    );
    
    const showSuggested = useMemo(
      () => (map?.find?.((i) => i.count > 0) ? false : ps?.suggested),
      [map]
    );

    const users = useMemo(() => {
      if (myFirstDegreeConnections?.length) {
        return toShow?.users
          ?.map?.((u) => ({
            ...(u ?? {}),
            order: myFirstDegreeConnections?.find?.((u_) => u_?.id === u?.id)
              ?.id
              ? 0
              : 1,
          }))
          ?.sort((a, b) => a - b);
      }
      return toShow?.users ?? [];
    }, [toShow?.users, myFirstDegreeConnections]);

    useEffect(() => {
      if (!myFirstDegreeConnections || myFirstDegreeConnections?.length <= 0) {
        refetchMyFirstDegreeConnections?.();
      }
    }, []);

    // console.log(comment_by);

    if (pinned) {
      return (
        <HStack py={3} px={4} >
          {/* <Box
            display="flex"
            bg="#E4E6EB"
            w="28px"
            h="28px"
            justifyContent="center"
            boxShadow="2px 0px 6px 0px #00000033"
            alignItems="center"
            borderRadius="full"
          >
              <PindIcon color="rgba(0,0,0,0.3)" width="16" height="16" />
          </Box> */}
          <Circle
            bg="#D9D9D9"
            w="28px"
            h="28px"
            boxShadow="0px -5px 3px 0px #00000008 inset, 0px 4px 5px 0px #0000000F inset, 0px 0px 10px 0px #00000029"
          >
            <PinnedIcon />
          </Circle>

          <HStack spacing={1}>
            <Text fontSize="sm" color="#828282">
              {'Pinned'}
            </Text>
          </HStack>
        </HStack>
      );
    }

    if (toShow.count <= 0) {
      return <></>;
    }

    return (
      <>
        {showSuggested ? (
          <HStack p={4}>
            {/* <Box display="flex"
              bg="#E4E6EB"
              w="28px"
              h="28px"
              justifyContent="center"
              boxShadow="2px 0px 6px 0px #00000033"
              alignItems="center"
              borderRadius="full">
              <SuggestedPostIcon />
            </Box> */}
            <Circle
              bg="#D9D9D9"
              w="28px"
              h="28px"
              boxShadow="0px -5px 3px 0px #00000008 inset, 0px 4px 5px 0px #0000000F inset, 0px 0px 10px 0px #00000029"
            >
              <SuggestedIcon />
            </Circle>
            <Text>Suggested for you</Text>
          </HStack>
        ) : (
          <HStack py={3} px={4}>
            {/* <InsightPopover content={commentBy}> */}
            <Circle
              bg="#D9D9D9"
              w="28px"
              h="28px"
              boxShadow="0px -5px 3px 0px #00000008 inset, 0px 4px 5px 0px #0000000F inset, 0px 0px 10px 0px #00000029"
            >
              {toShow.icon}
            </Circle>
            <HStack spacing={0}>
              <AvatarGroup color="#828282" size="sm" max={maxUserCount}>
                {users?.map?.((u, uInd) => (
                  <Avatar
                    as={Link}
                    href={
                      u?.name?.length > 0 ? '/c/' + u?.slug : '/p/' + u?.slug
                    }
                    key={uInd}
                    size="sm"
                    fontSize="10px"
                    src={u?.name?.length > 0 ? u?.image : getUserProfilePic(u)}
                    name={getUserFullName(u)}
                  />
                ))}
              </AvatarGroup>
              <Text fontSize="16px" color="#828282">
                {toShow.append}
              </Text>
            </HStack>
            {/* </InsightPopover> */}
          </HStack>
        )}
      </>
    );
  }
);
